import ApiService from "./api.service";

const user = localStorage.getItem("user_info") && JSON.parse(localStorage.getItem("user_info"))

export const getCompanies = async (payload) => {
  const response = await ApiService.get("/companies/paginate", {
    params: { ...payload }
  });
  if(response.data){
    return response.data
  }
};

export const getCompanyById = async (id) => {
  const companyId = id || user.company._id
  return await ApiService.get(`/companies/${companyId}`, {
  });
};

export const createCompany = async payload => {
  return await ApiService.post("/companies", payload);
};

export const updateCompanyProfile = async (companyId, payload) => {
  return await ApiService.put(`/companies/${companyId}`, payload);
};

export const getBusinessCategory = async () => {
  return await ApiService.get("/companies/category");
};

export const getCompanyJobs = async (companyId) => {
  return await ApiService.get(`/companies/${companyId}/jobs`, {
  });
};
